.section{
    p{
        color: $gun-metal;
        font-size: $text-lg;
        line-height: $line-height-lg;
        font-weight: 300;
        font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    
    ul{
        margin-left: 0.625rem;
        margin-bottom: 2rem;
        margin-top: 1rem;
        list-style-type: disc;
        list-style-position: inside;
    }

    li{
        margin-top: 0.75rem;
        color: $gun-metal;
        font-size: $text-lg;
        line-height: $line-height-lg;
        font-weight: 300;
        font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
}