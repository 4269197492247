//colors
$gun-metal: rgba(42, 52, 60, 1);
$cherry-red-300: #BB4850;
$cherry-red: #ad222b;
$cherry-red-700: #86161C;

//sizes
$text-lg: 1.125rem;

//line heights
$line-height-lg:1.75;