.card{
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

    
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .2);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
}