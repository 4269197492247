.sign-in {
    form {
        margin-top: 3rem;
    }

    label {
        display: block;
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.25;
        font-weight: 600;
        font-family: "Open Sans", "Courier New", Courier, monospace;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        //var
        $margin-bottom: 1.5rem;

        //style
        display: block;
        width: 100%;
        outline: none;
        padding: 1rem;
        border-radius: 0.5rem;
        -webkit-border-radius: 0.5rem;
        -moz-border-radius: 0.5rem;
        -khtml-border-radius: 0.5rem;
        margin-bottom: $margin-bottom;

        &:focus {
            border: 1px solid $cherry-red;
            margin-bottom: calc(#{$margin-bottom}-1px);
            margin-top: -1px;
        }
    }

    .form-group {
        display: flex;
        flex-direction: row;
        width: 100%;

        div{
            width: 100%;

            &+ div{
                margin-left: 2rem;
            }
        }
    }

    .terms-and-conditions{
        display: flex;
        margin: .5rem 0 1rem 0;
        flex-direction: row;
        align-items: center;

        label{
            margin-bottom: 0;
            margin-left: .5rem;
        }

        input[type="checkbox"]{
            height: 1rem;
            width: 1rem;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: 1px solid #989a9c;
            border-radius: 3px;
            outline: none;

            &:checked {
                border: 1px solid $cherry-red-700;
                background-color: $cherry-red-300;
            }
        }
    }
}

.user-settings {
    label {
        display: block;
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.25;
        font-weight: 600;
        font-family: "Open Sans", "Courier New", Courier, monospace;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        display: block;
        width: 100%;
        outline: none;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 2px solid #666c71;
        -webkit-border-radius: 0.5rem;
        -moz-border-radius: 0.5rem;
        -khtml-border-radius: 0.5rem;
        margin-bottom: 1.5rem;

        &:focus {
            border: 2px solid #ad222b;
            background-color: #fdf8f9;
        }
    }
}

.contacts {
    label {
        display: block;
        margin-bottom: 1rem;
        font-size: 1rem;
        line-height: 1.25;
        font-weight: 600;
        font-family: "Open Sans", "Courier New", Courier, monospace;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        display: block;
        width: 100%;
        outline: none;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 2px solid #666c71;
        -webkit-border-radius: 0.5rem;
        -moz-border-radius: 0.5rem;
        -khtml-border-radius: 0.5rem;
        margin-bottom: 1.5rem;
        resize: none;

        &:focus {
            border: 2px solid #ad222b;
            background-color: #fdf8f9;
        }
    }
}
