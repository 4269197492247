.tw-docs-card {
    @apply text-gunmetal-400 w-10/12 md:w-2/3 p-6 sm:p-8 text-sm sm:text-base relative left-1/2 transform -translate-x-1/2;
}

.tw-docs-card > section {
    @apply mt-8;
}

.tw-docs-card h1 {
    @apply text-3xl sm:text-4xl font-bold;
}

.tw-docs-card h1 + p {
    @apply pt-4
}

.tw-docs-card h2 {
    @apply text-xl sm:text-2xl font-semibold;
}

.tw-docs-card h2 + div {
    @apply mt-4;
}

.tw-docs-card ul {
    @apply list-disc list-outside ml-8 space-y-2 py-4;
}
