.cherry-button {
  transition: background 0.2s, box-shadow 0.2s, color 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  &:disabled{
    cursor: not-allowed;
  }

  &:focus{
    outline: none;
  }

  &.raised-text-button {
    &.pressed {
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
    }
  }

  &.raised-backgrounded-button {
    &.pressed {
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.6), 0 2px 6px 2px rgba(60, 64, 67, 0.3);
    }
  }
}
