//AUTO-SIZE INPUT
.autosize{
    input{
        &:focus{
            outline: none;
        }
    }
}

//REACT-HIGHLIGHT
pre{
    margin: 0px;
}