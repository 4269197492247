body {
    margin: 0;
    font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f7fb;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Cooper Hewitt";
    src: local("Cooper Hewitt"), url(../../assets/fonts/CooperHewitt-Medium.otf) format("truetype");
}

@font-face {
    font-family: "Intro Black Inline";
    src: local("Intro Black Inline"), url(../../assets/fonts/IntroDemo-BlackCAPS.otf) format("truetype");
}

* {
    box-sizing: border-box;
}

.preferred-plan {
    transform: scaleX(1.05) scaleY(1.05);
    transform-style: preserve-3d;
}

.preferred-plan::before {
    content: "";
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    background-image: linear-gradient(to bottom right, #ca313b, #ea4651);
    border-radius: 0.75rem;
    transform: translateZ(-1em);
}
